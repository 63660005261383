body {
    background-color: black;
    color: white;
}

@font-face { 
    font-family: 'Sitka'; 
    src: url('sitka-small-652.ttf') format('truetype'); 
} 

.generated-signature {
    font-family: "Sitka" !important;
}

a {
    color: #364BA0;
    font-family: helvetica, bold;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
    /* text-transform: uppercase; */
}

td {
    vertical-align: top;
    padding-bottom: 5px;
    padding-right: 7px;
}

.align-bottom {
    height: 60%;
    vertical-align: bottom;
}

.table-height {
    height: 100%;
}

.signature {
    height: 100px;
    max-width: 100%;
    white-space: nowrap;
    background: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
}

.blue-bold-text {
    color: #4899d5;
    font-family: helvetica, bold;
    font-weight: bold;
    font-size: 14px;
}

.regular-text {
    color: #A2A8AB;
    font-family: helvetica;
    font-size: 14px;
}

.main-image {
    width: 120px;
    height: 90px;
}


div.space-even-spans {
    /* padding-top: 20px; */
    justify-content: center;
    align-items: center;
    display: table;
    /* width: 100%; */
    table-layout: fixed;    /* For cells of equal size */
}
div.space-even-spans div {
  display: table-cell;
  /* width: 70px; */
  /* text-align: center; */
}

.MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: white;
}


@media (max-width: 995px) {
    .MuiGrid-container {
        display: flex !important;
        flex-direction: column-reverse;
    }
    .MuiGrid-grid-xs-6 {
        max-width: 100% !important;
    }
}

.makeStyles-root-1 .MuiTextField-root {
    margin: 13px 0px 0px 0px !important;
}

.jss1 .MuiTextField-root {
    margin: 13px 0px 0px 0px !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #364BA0;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #364BA0 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #5d70bc !important;
}

.MuiButton-root {
    background-color: #364BA0;
    color: white !important;
    margin-right: 15px !important;
}
.MuiButton-root:hover {
    background-color: #5d70bc;
    color: white !important;
    margin-right: 15px !important;
}
.MuiButton-root:disabled {
    background-color: #7e7e7e97;
    color: white !important;
    margin-right: 15px !important;
}

#clear-btn {
    background-color: #ff9100 !important;
}
#clear-btn:hover {
    background-color: #fbaf4c !important;
}
#clear-btn:disabled {
    /* background-color: #ff910075 !important; */
    background-color: #7e7e7e97 !important;
}

.loader {
    width: 24px;
    height: 24px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

.MuiPaper-elevation2, .css-80pr5n-MuiPaper-root {
    box-shadow: 0px 0px 0px 0px !important;
}


.css-ns6qhq-MuiTableCell-root, .css-pwcg7p-MuiCollapse-root {
    width: 100%;
}


.react-colorful {
    width: auto !important;
}